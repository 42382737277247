// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body #app .card-body {
  background-color: teal;
}
body #app .card {
  width: 250px;
}
body #app #circle-avatar {
  display: grid;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ffbd30;
  place-items: center;
}
body #app #circle-avatar span {
  font-size: 48px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/style/profile.scss"],"names":[],"mappings":"AAII;EAEI,sBAAA;AAJR;AAOM;EACE,YAAA;AALR;AASM;EACE,aAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;AAPR;AASQ;EACE,eAAA;EACA,iBAAA;AAPV","sourcesContent":["body {\r\n\r\n    #app {\r\n\r\n    .card-body\r\n    {\r\n        background-color: teal ;\r\n    }\r\n\r\n      .card {\r\n        width: 250px;\r\n        // overflow: auto;\r\n      }\r\n  \r\n      #circle-avatar {\r\n        display: grid;\r\n        width: 100px;\r\n        height: 100px;\r\n        border-radius: 50%;\r\n        background-color: #ffbd30;\r\n        place-items: center;\r\n  \r\n        span {\r\n          font-size: 48px;\r\n          font-weight: bold;\r\n        }\r\n      }\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
