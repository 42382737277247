// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
.container1 {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-height: 90vh; /* Set a maximum height to limit the scrolling area */
    overflow-y: scroll; /* Enable scrolling */
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer/Edge */
    margin-top: 10px; /* Add top margin to create space between the navbar and the form */
  }
  
  .container::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
  }
  
  .input-error {
    color: red;
  }
  
  .edit-icon
  {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
  }

  .edit-icon-label
  {
    width: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/style/formstyle.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,uCAAuC;IACvC,gBAAgB,EAAE,qDAAqD;IACvE,kBAAkB,EAAE,qBAAqB;IACzC,qBAAqB,EAAE,mCAAmC;IAC1D,wBAAwB,EAAE,kDAAkD;IAC5E,gBAAgB,EAAE,mEAAmE;EACvF;;EAEA;IACE,QAAQ,EAAE,wEAAwE;EACpF;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,UAAU;EACZ;;EAEA;;IAEE,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,2BAA2B;EAC7B;;EAEA;;IAEE,WAAW;EACb","sourcesContent":["/* App.css */\r\n.container1 {\r\n    max-width: 700px;\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\r\n    max-height: 90vh; /* Set a maximum height to limit the scrolling area */\r\n    overflow-y: scroll; /* Enable scrolling */\r\n    scrollbar-width: none; /* Hide the scrollbar for Firefox */\r\n    -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer/Edge */\r\n    margin-top: 10px; /* Add top margin to create space between the navbar and the form */\r\n  }\r\n  \r\n  .container::-webkit-scrollbar {\r\n    width: 0; /* Hide the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */\r\n  }\r\n  \r\n  .form-group {\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  label {\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .input-error {\r\n    color: red;\r\n  }\r\n  \r\n  .edit-icon\r\n  {\r\n    display: flex;\r\n    justify-content: center;\r\n    width: 100%;\r\n    /* background-color: red; */\r\n  }\r\n\r\n  .edit-icon-label\r\n  {\r\n    width: 100%;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
