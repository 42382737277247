// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure the card is relatively positioned */
.card {
  position: relative;
}

/* Delete Icon Container */
.delete-icon-container {
  position: absolute; /* Set the container to absolute positioning */
  top: 10px; /* Position it at the top */
  right: 10px; /* Position it on the right */
  visibility: hidden; /* Hide by default */
  opacity: 0; /* Set initial opacity */
  transition: visibility 0s, opacity 0.2s linear; /* Smooth transition */
}

/* Show delete icon on card hover */
.card:hover .delete-icon-container {
  visibility: visible; /* Show icon on hover */
  opacity: 1; /* Set opacity to fully visible */
}

.card:hover {
  border-color: rgba(13, 110, 253, 0.7);
  box-shadow: 0 0 10px 2px rgba(13, 110, 253, 0.6);
}

.card .delete-icon {
  visibility: hidden; /* Hide the icon initially */
  opacity: 0; /* Set initial opacity */
  transition: visibility 0s, opacity 0.2s linear; /* Smooth transition */
}

.card:hover .delete-icon {
  visibility: visible; /* Show icon on hover */
  opacity: 1; /* Set opacity to fully visible */
}
`, "",{"version":3,"sources":["webpack://./src/style/plant.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;EACE,kBAAkB;AACpB;;AAEA,0BAA0B;AAC1B;EACE,kBAAkB,EAAE,8CAA8C;EAClE,SAAS,EAAE,2BAA2B;EACtC,WAAW,EAAE,6BAA6B;EAC1C,kBAAkB,EAAE,oBAAoB;EACxC,UAAU,EAAE,wBAAwB;EACpC,8CAA8C,EAAE,sBAAsB;AACxE;;AAEA,mCAAmC;AACnC;EACE,mBAAmB,EAAE,uBAAuB;EAC5C,UAAU,EAAE,iCAAiC;AAC/C;;AAEA;EACE,qCAAqC;EACrC,gDAAgD;AAClD;;AAEA;EACE,kBAAkB,EAAE,4BAA4B;EAChD,UAAU,EAAE,wBAAwB;EACpC,8CAA8C,EAAE,sBAAsB;AACxE;;AAEA;EACE,mBAAmB,EAAE,uBAAuB;EAC5C,UAAU,EAAE,iCAAiC;AAC/C","sourcesContent":["/* Ensure the card is relatively positioned */\r\n.card {\r\n  position: relative;\r\n}\r\n\r\n/* Delete Icon Container */\r\n.delete-icon-container {\r\n  position: absolute; /* Set the container to absolute positioning */\r\n  top: 10px; /* Position it at the top */\r\n  right: 10px; /* Position it on the right */\r\n  visibility: hidden; /* Hide by default */\r\n  opacity: 0; /* Set initial opacity */\r\n  transition: visibility 0s, opacity 0.2s linear; /* Smooth transition */\r\n}\r\n\r\n/* Show delete icon on card hover */\r\n.card:hover .delete-icon-container {\r\n  visibility: visible; /* Show icon on hover */\r\n  opacity: 1; /* Set opacity to fully visible */\r\n}\r\n\r\n.card:hover {\r\n  border-color: rgba(13, 110, 253, 0.7);\r\n  box-shadow: 0 0 10px 2px rgba(13, 110, 253, 0.6);\r\n}\r\n\r\n.card .delete-icon {\r\n  visibility: hidden; /* Hide the icon initially */\r\n  opacity: 0; /* Set initial opacity */\r\n  transition: visibility 0s, opacity 0.2s linear; /* Smooth transition */\r\n}\r\n\r\n.card:hover .delete-icon {\r\n  visibility: visible; /* Show icon on hover */\r\n  opacity: 1; /* Set opacity to fully visible */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
