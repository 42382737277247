// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navbar container */
.navbar {
    background-color: teal;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  /* Logo and title on the left */
  .navbar-left {
    display: flex;
    align-items: center;
  }
  
  .navbar-logo {
    width: 40px; /* Adjust the logo width as needed */
    height: auto;
    margin-right: 10px;
  }
  
  .navbar-title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  /* Navigation links on the right */
  .navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-link {
    margin-right: 20px;
  }
  
  .navbar-link a {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  
  /* Change link color on hover */
  .navbar-link a:hover {
    color: lightgray;
  }
  

`, "",{"version":3,"sources":["webpack://./src/style/header.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA,+BAA+B;EAC/B;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,WAAW,EAAE,oCAAoC;IACjD,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA,kCAAkC;EAClC;IACE,gBAAgB;IAChB,aAAa;IACb,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;EACnB;;EAEA,+BAA+B;EAC/B;IACE,gBAAgB;EAClB","sourcesContent":["/* Navbar container */\r\n.navbar {\r\n    background-color: teal;\r\n    color: white;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 10px 20px;\r\n  }\r\n  \r\n  /* Logo and title on the left */\r\n  .navbar-left {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  \r\n  .navbar-logo {\r\n    width: 40px; /* Adjust the logo width as needed */\r\n    height: auto;\r\n    margin-right: 10px;\r\n  }\r\n  \r\n  .navbar-title {\r\n    font-weight: bold;\r\n    font-size: 1.2rem;\r\n  }\r\n  \r\n  /* Navigation links on the right */\r\n  .navbar-links {\r\n    list-style: none;\r\n    display: flex;\r\n    margin: 0;\r\n    padding: 0;\r\n  }\r\n  \r\n  .navbar-link {\r\n    margin-right: 20px;\r\n  }\r\n  \r\n  .navbar-link a {\r\n    text-decoration: none;\r\n    color: white;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  /* Change link color on hover */\r\n  .navbar-link a:hover {\r\n    color: lightgray;\r\n  }\r\n  \r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
