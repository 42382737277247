import React from 'react'
import SheduledDelivery from './SheduledDelivery'

const Cancelled = () => {
  console.log("hiiiiiiiiiiiiiiiiiiiiii")
  return (
    <>
    <SheduledDelivery type={'cancel'}/>
    </>
  )
}

export default Cancelled