// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search 
{
 display: flex;
 align-items: center;
 justify-content: center;
 width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/style/searchbar.css"],"names":[],"mappings":"AAAA;;CAEC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,UAAU;AACX","sourcesContent":[".search \r\n{\r\n display: flex;\r\n align-items: center;\r\n justify-content: center;\r\n width: 50%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
